import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from '../components/pageheading'
import ContentImage from '../components/contentimage'


const NewBuildsPage = ({data}) => (

    <Layout pageClass="info-page new-builds-page">
    <SEO title="New Builds" description="Modus partners with architects and clients who have a distinctive vision." />
    <PageHeading title="New Builds" imageData={data.headerImage} />
    <main className="main">
        <div>
        <div className="typography inner">
      
            <div className="text-holder">
    
                <p>
                    We believe a tight team of knowledgeable people will always deliver the best result. So we look to partner with architects and clients who have a distinctive vision – and who understand the value an experienced builder can bring.
                </p>
        
                <p>
                    Every new build starts with an original design, which is a response to the site as well as a reflection of the client’s vision. It may be the first, second or third home they have built with us. Whatever the case, we will share our professional knowledge and help them create something that’s supremely functional as well as beautiful.
                </p>
        
                <p>
                    Your new build project will benefit from having the same Modus Developments team on site from start to finish. Our Site Supervisor will be available to talk at any stage and deal with issues that may crop up. We take pride in the quality of our workmanship, so any novel design elements or custom features will be thoughtfully planned and delivered with care. 
                </p>
        
                <p className="last">
                    Our trusted team of subcontractors will be brought in and managed professionally. If required, Modus Developments can deliver the extra features that bring a property to life – from swimming pools to landscaping. 
                </p>
            </div>

            <ContentImage image={data.contentImage} alt="room view through to back" />

        </div>
        </div>
    </main>
  </Layout>

)

export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "AE1749.jpg"}}) { 
            ...ResponsiveImage
        }
        contentImage: imageSharp(resolutions: {originalName: {eq: "Richmond-view-through-back.jpg"}}) { 
            big: fluid (maxWidth: 630, maxHeight: 655, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            medium: fluid (maxWidth: 435, maxHeight: 918, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            small: fluid (maxWidth: 720, maxHeight: 540, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
        }
    
    }
`
export default NewBuildsPage